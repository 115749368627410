import Folder from "../folder/Folder";

const Folders = ({ allFolders,getFolders }) => {

    return (
        <>
            {allFolders.length === 0 &&
                <p>No folder found</p>
            }
            <div className='grid grid-cols-8 gap-4 mt-5'>
                {allFolders?.map((folder) => (
                    <Folder
                        key={folder?._id}
                        folder={folder}
                        getFolders={getFolders}
                    />
                ))}

            </div>
        </>
    )
}

export default Folders