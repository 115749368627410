import React from 'react'
import { Bar } from 'react-chartjs-2';

const WorkingStatistics = () => {
    const salesData = {
        labels: [
            'Apr 01',
            'Apr 02',
            'Apr 03',
            'Apr 04',
            'Apr 05',
            'Apr 06',
            'Apr 07'
        ],
        fontColor: "white",
        datasets: [
            {
                label: "working hours / Month",
                data: [2, 4, 6, 8, 3, 5, 7],
                backgroundColor: ["#4C52C4", "#4C52C4", "#4C52C4", "#4C52C4", "#4C52C4", "#4C52C4", "#FF8C4C"],
                borderColor: ["white"],
                borderWidth: 2,
            },
        ],
    };

    const options = {
        borderRadius: 30, // Adjust this value as needed
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };

    return (
        <div className='w-[40%] shadow rounded-xl py-2 px-4'>
            <div className='flex justify-between items-center gap-2'>
                <h2 className='text-lg font-bold'>Working Statistics</h2>
                <select className="border rounded-md">
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                </select>
            </div>
            <Bar data={salesData} options={options} />
        </div>
    )
}

export default WorkingStatistics