import React from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { Slider } from '../ui/slider'
import { Progress } from '../ui/progress'
import { Button } from '../ui/button'
import OverlappingImages from '../OverlappingImages'
import { readCookie } from '@/utils/readCookie'
import { useNavigate, useParams } from 'react-router-dom'

const images = [
    "https://s3-alpha-sig.figma.com/img/e24d/ab51/80333c995e2968bc36c4670edc3679d5?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QC6abKoIuNkhzPjm1X2jU7eUbWi6Z9886uFo8WvLAP8pU3D0GUB3h5ws4dTkOH2rdF3wZEn9e9YJt1EBgc7L~MzZQLo1uGf8cAT6eERbOyIXWz2Qod3sa-3rJ2v49ISDvOt0G4TON8s0vSVYYX20rjgM3TTVbe-xQy1UUFK8mcJDP0pqn0-rF6VV41yvC00d7gQeAXmjLTW-URP2NXuB8sauH~UHRRr1L-8ZvRZMYR-HgNRkKREVUvzICkkPQI6kujk4eJvRkkJmDPv-68Dz~QQ3Y8lYXnQbL3Soy8Q5Un2UNh4V7HODXtQKLG0gfEWC2Lfd1VYko5aXgosxFEnQ5g__",
    "https://s3-alpha-sig.figma.com/img/e24d/ab51/80333c995e2968bc36c4670edc3679d5?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QC6abKoIuNkhzPjm1X2jU7eUbWi6Z9886uFo8WvLAP8pU3D0GUB3h5ws4dTkOH2rdF3wZEn9e9YJt1EBgc7L~MzZQLo1uGf8cAT6eERbOyIXWz2Qod3sa-3rJ2v49ISDvOt0G4TON8s0vSVYYX20rjgM3TTVbe-xQy1UUFK8mcJDP0pqn0-rF6VV41yvC00d7gQeAXmjLTW-URP2NXuB8sauH~UHRRr1L-8ZvRZMYR-HgNRkKREVUvzICkkPQI6kujk4eJvRkkJmDPv-68Dz~QQ3Y8lYXnQbL3Soy8Q5Un2UNh4V7HODXtQKLG0gfEWC2Lfd1VYko5aXgosxFEnQ5g__",
]

// const status = "completed"
const status = "created"

const SingleGridTodo = ({ todo }) => {
    const cookies = readCookie("userInfo");
    const navigate = useNavigate();
    const params = useParams();

    const handleClick = () => {
        if (cookies?.userType === "admin") {
            navigate(`/employees/${params?.empId}/todos/${todo?._id}`)
        }
        else {
            navigate(`/todos/${todo?._id}`);
        }
    }

    return (
        <div className='w-full bg-white shadow-[0px_0px_6px_rgba(0,0,0,0.2)] rounded-lg px-4 py-3'>
            <div className='flex justify-between items-start gap-2'>
                <div className={`px-3 py-1 rounded-2xl capitalize ${status === "completed" ? "bg-[#4CAC4A99] text-[#02A611]" : "bg-[#85ABFF] text-[#0B2D79]"}`}>{status}</div>
                <BsThreeDots className='cursor-pointer text-3xl text-[#4B5563]' />
            </div>
            <p className='font-bold mt-3 text-[#5F5F5F]'>{todo?._id}</p>
            <div className='flex items-center gap-2 mt-2'>
                <Progress bg="bg-[#7CD4E8]" className="h-2 mt-1" value={50} />
                <span className='text-sm font-medium text-[#7CD4E8]'>100%</span>
            </div>

            <div className="flex justify-between gap-2 items-center mt-4">
                <OverlappingImages
                    images={images}
                />
                <Button onClick={handleClick} className="bg-[#8AAFB8] hover:bg-[#78abb7] text-sm w-40 h-7">View detail</Button>
            </div>
        </div>
    )
}

export default SingleGridTodo