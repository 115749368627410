import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import usePatchApiReq from '@/hooks/usePatchApiReq';
import usePostApiReq from '@/hooks/usePostApiReq';
import { AddTodoSchema } from '@/schemas/AddTodoSchema';
import { generateTimeOptions } from '@/utils/generateTimeOptions';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Loader from '../loader/Loader';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Textarea } from '../ui/textarea';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { cn } from '@/lib/utils';
import { MdCalendarMonth } from 'react-icons/md';
import { format } from 'date-fns';
import { Calendar } from '../ui/calendar';

const AddTodo = ({ isAddTodoModalOpen, setIsAddTodoModalOpen, empId, todo = "", getTodos }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: res1, fetchData: fetchData1, isLoading: isLoading1 } = usePatchApiReq();

    const [isCalender, setIsCalender] = useState(false)
    const timeOptions = generateTimeOptions();

    const form = useForm({
        resolver: zodResolver(AddTodoSchema),
        defaultValues: {
            title: todo?.title || "",
            description: todo?.description || "",
            priority: todo?.priority || "",
            date: (todo?.date && new Date(todo?.date)) || new Date(),
            time: todo?.time || "",
        }
    })

    const { reset, handleSubmit, setValue, getValues } = form

    console.log("getValues", getValues());


    const handleDateSelect = (value) => {
        setValue("date", value)
        setIsCalender(false);
    }
    const onSubmit = (data) => {
        console.log("data", data);

        if (todo) {
            fetchData1("/admin/update-todo", {
                title: data.title,
                description: data.description,
                date: data.date && format(new Date(data.date), "yyyy-MM-dd"),
                time: data.time,
                priority: data.priority,
                empId,
                todoId: todo?._id
            })
        }
        else {
            fetchData("/admin/create-todo", {
                title: data.title,
                description: data.description,
                date: data.date && format(new Date(data.date), "yyyy-MM-dd"),
                time: data.time,
                priority: data.priority,
                empId
            })
        }
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("api res", res?.data);
            setIsAddTodoModalOpen(false);
            getTodos();
        }
    }, [res])

    useEffect(() => {
        if (res1?.status === 200 || res1?.status === 201) {
            console.log("api res", res?.data);
            setIsAddTodoModalOpen(false);
            getTodos();
        }
    }, [res1])

    return (
        <Dialog open={isAddTodoModalOpen} onOpenChange={setIsAddTodoModalOpen}>
            <DialogContent className="max-w-[700px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <div className="upper flex flex-col items-start gap-[15px] max-large:gap-1">
                        <h2 className='text-[#000A2D] text-2xl font-bold font-inter max-large:text-3xl max-med:hidden'>{todo ? "Update" : "Add"} Todo</h2>
                    </div>
                    <DialogTitle></DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Title</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Enter Title"

                                            type="text"
                                            {...field} />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            placeholder="Enter Description"
                                            className="resize-none"
                                            {...field}
                                        />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="priority"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Priority Level</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select Priority Level" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectItem value="low">Low</SelectItem>
                                            <SelectItem value="medium">Medium</SelectItem>
                                            <SelectItem value="high">High</SelectItem>
                                        </SelectContent>
                                    </Select>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="date"
                            render={({ field }) => (
                                <FormItem className="flex flex-col">
                                    <FormLabel>Date</FormLabel>
                                    <Popover open={isCalender} onOpenChange={setIsCalender}>
                                        <PopoverTrigger asChild>
                                            <FormControl>
                                                <Button
                                                    variant={"outline"}
                                                    className={cn(
                                                        "w-full flex h-12 border-[#808080] gap-2 justify-start text-[#717171] max-med:px-3 max-med:h-[46px] max-med:rounded-lg",
                                                        !field.value && "text-muted-foreground"
                                                    )}
                                                >
                                                    <MdCalendarMonth className='text-[#838383] text-xl' />
                                                    {field.value ? (
                                                        format(field.value, "PPP")
                                                    ) : (
                                                        <span className='text-[#838383] text-base font-normal max-med:text-sm'>Select a date</span>
                                                    )}
                                                </Button>
                                            </FormControl>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0" align="start">
                                            <Calendar
                                                mode="single"
                                                selected={field.value}
                                                onSelect={handleDateSelect}
                                                disabled={(date) => {
                                                    const today = new Date();
                                                    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight
                                                    return date < today; // Disable dates before today
                                                }}
                                                initialFocus
                                            />
                                        </PopoverContent>
                                    </Popover>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="time"
                            render={({ field }) => (
                                <FormItem className="flex flex-col">
                                    <FormLabel>Time</FormLabel>
                                    <Select
                                        value={field.value}
                                        onValueChange={field.onChange}
                                    >
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select Time" />
                                        </SelectTrigger>
                                        <SelectContent className="overflow-y-auto max-h-[10rem]">
                                            <SelectGroup>
                                                {timeOptions.map((time, idx) => (
                                                    <SelectItem key={idx} value={time}>
                                                        {time}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <Button type="submit" className="w-full h-10 text-base">
                            {isLoading ? <Loader size={30} /> : todo ? "Update" : "Add"}
                        </Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddTodo