import React from 'react'
import { IoMdSearch } from 'react-icons/io'

const Search = ({ placeholder, setSearchInput, searchInput, width = "w-[340px]" }) => {
    return (
        <div className={`grid grid-cols-[1fr_40px] ${width} items-center`}>
            <input placeholder={placeholder} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className="ml-1 rounded-tl-lg rounded-bl-lg border-2 h-10 px-3 text-black" type="text" name="" id="" />
            <div className="bg-[#374151] rounded-tr-lg rounded-br-lg h-full flex justify-center items-center">
                <IoMdSearch className="text-white text-xl" />
            </div>
        </div>
    )
}

export default Search