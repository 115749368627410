import Break from '@/components/break/Break';
import Loader from '@/components/loader/Loader';
import usePatchApiReq from '@/hooks/usePatchApiReq';
import { axiosInstance } from '@/utils/axiosInstance';
import { readCookie } from '@/utils/readCookie';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AllBreaks = ({ handleMonthChange, getStatus, statusData,allBreaks,getBreaks,isBreakLoading }) => {
    const { res: breakRes, fetchData: breakFetchData, isLoading: breakIsLoading } = usePatchApiReq();
    const { res: availableRes, fetchData: availableFetchData, isLoading: availableIsLoading } = usePatchApiReq();


    // const getBreaks = async () => {
    //     const id = cookies?.userId;
    //     try {
    //         const { data } = await axiosInstance.get(`/admin/get-emp-atd-details/${id}`);
    //         console.log("emp att all breaks", data);

    //         setAllBreaks(data?.data[0]?.breaks || []);
    //     } catch (error) {
    //         console.log(error);
    //     } finally {
    //         setIsBreakLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     getBreaks();
    // }, []);


    useEffect(() => {
        if (breakRes?.status === 200 || breakRes?.status === 201) {
            getBreaks();
            getStatus();
            handleMonthChange(new Date());
        }
    }, [breakRes])

    const handleAvailable = async (breakId) => {
        availableFetchData(`/admin/update-emp-atd/${statusData?.details?.attendanceId}`, { objId: breakId, status: "available" });
    };

    useEffect(() => {
        if (availableRes?.status === 200 || availableRes?.status === 201) {
            console.log("break", availableRes?.data);
            getBreaks();
            getStatus();
            handleMonthChange(new Date());
        }
    }, [availableRes])

    return (
        <div>
            <h3 className="text-2xl font-bold py-2 mt-4">
                All Breaks
            </h3>
            <div className="flex flex-wrap gap-3 mt-2">
                {allBreaks?.length === 0 && !isBreakLoading && <p>No breaks found</p>}

                {allBreaks?.length === 0 && isBreakLoading && (
                    <Loader />
                )}

                {allBreaks?.map((singleBreak, i) => (
                    <Break
                        key={singleBreak?._id}
                        singleBreak={singleBreak}
                        i={i}
                        handleAvailable={handleAvailable}
                    />
                ))}
            </div>
        </div>
    )
}

export default AllBreaks