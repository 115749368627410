import TodaysTodos from '@/components/todos/TodaysTodos'
import React from 'react'
import { useParams } from 'react-router-dom';

const TodaysTodosPageAdmin = () => {
  const params = useParams();

  return (
    <div>
      <TodaysTodos
        viewAllBtn={false}
        date={params?.date || ""}
      />
    </div>
  )
}

export default TodaysTodosPageAdmin