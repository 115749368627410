import { useEffect, useState } from 'react'
import SubSubFolder from '../subSubfolder/SubSubFolder';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetApiReq from '@/hooks/useGetApiReq';
import Doc from '../document/Doc';

const SubSubFolders = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();
    const navigate = useNavigate();
    const { state } = useLocation()

    const [subFolderDocs, setSubFolderDocs] = useState([]);
    const [isSubFolderOpen, setIsSubFolderOpen] = useState(false);
    const [subFolderId, setSubFolderId] = useState("");
    const [allSubSubFolders, setAllSubSubFolders] = useState([]);
    const [isAddSubFolderModalOpen, setIsAddSubFolderModalOpen] = useState(false);


    const getSubFolders = () => {
        fetchSubFolderData(`/admin/get-folder/${state?.folderId}?flag=sub-folder`);
    }

    useEffect(() => {
        getSubFolders();
    }, [])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("sub 'sub' FolderRes", subFolderRes);
            setAllSubSubFolders(subFolderRes?.data?.subfolders);
            setSubFolderDocs(subFolderRes?.data?.docs);
        }
    }, [subFolderRes])

    return (
        <>
            {allSubSubFolders.length === 0 && subFolderDocs.length === 0 &&
                <p>No folder found</p>
            }
            <div className='grid grid-cols-7 gap-4 mt-5'>
                {allSubSubFolders?.map((subSubfolder) => (
                    <SubSubFolder
                        key={subSubfolder?._id}
                        setFolderIdAndType={() => { }}
                        subfolder={subSubfolder}
                        folderIdAndType={"folderIdAndType"}
                        isDownload={"isDownload"}
                        getSubFolders={getSubFolders}
                    />
                ))}
                {
                    subFolderDocs?.map((doc) => (
                        <Doc
                            key={doc?._id}
                            doc={doc}
                        />
                    ))
                }
            </div>

        </>
    )
}

export default SubSubFolders