import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { axiosInstance } from '../../utils/axiosInstance';

import { BiSolidEdit, BiSolidTrashAlt } from 'react-icons/bi'

import classes from './Employee.module.css'
import toast from 'react-hot-toast';
import avatar from "../../assets/employee.png"

import AddEmployeeModal from '../add-employeeModal/AddEmployeeModal';
import DeleteModal from '../deleteModal/DeleteModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import { TableCell, TableRow } from '../ui/table';

const Employee = ({ employee, getAllEmployee, index }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [status, setStatus] = useState(employee?.status ? "active" : "inActive");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-employee/${employee._id}`);
            toast.success("Employee deleted successfully");
            getAllEmployee()
            setIsDeleteModal(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = async (e) => {
        if (e.target.checked) {
            setStatus("active");
            const { data } = await axiosInstance.patch(`/admin/change-employee-status/${employee._id}`, { status: true });
        }
        else {
            setStatus("inActive");
            const { data } = await axiosInstance.patch(`/admin/change-employee-status/${employee._id}`, { status: false });
        }
        toast.success("Employee status changed successfully");
        getAllEmployee();
    }


    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen || isDeleteModal) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen, isDeleteModal])




    return (
        <>
            <TableRow>
                <TableCell>
                    <div onClick={() => navigate(`/employees/${employee._id}`, { state: employee })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                        <img className={classes.img} src={employee.image ? `${import.meta.env.VITE_IMAGE_URL}/${employee.image}` : avatar} alt="employee" />
                    </div>
                </TableCell>
                <TableCell onClick={() => navigate(`/employees/${employee._id}`, { state: employee })} className="cursor-pointer">{employee.name}</TableCell>
                <TableCell>{employee.position}</TableCell>
                <TableCell>{employee.role}</TableCell>
                <TableCell>
                    <p className={`w-24 px-2 py-2 rounded-tl-xl font-semibold rounded-br-xl text-center text-white ${status === "active" ? "bg-green-400" : "bg-red-500"}`}>{status === "active" ? "Active" : "InActive"}</p>
                </TableCell>
                <TableCell>
                    <div className="flex gap-4">
                        <BiSolidEdit onClick={handleModal} cursor={"pointer"} size={24} />
                        <BiSolidTrashAlt onClick={() => setIsDeleteModal(true)} cursor={"pointer"} size={22} color="red" />
                    </div>
                </TableCell>
            </TableRow>
            {/* <div className={classes.employee_card}>
                <div onClick={() => navigate(`/employees/${employee._id}`, { state: employee })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                    <img className={classes.img} src={employee.image ? `${import.meta.env.VITE_IMAGE_URL}/${employee.image}` : avatar} alt="employee" />
                </div>
                <p onClick={() => navigate(`/employees/${employee._id}`, { state: employee })} className="w-36 cursor-pointer">{employee.name}</p>
                <p className={`${classes.position} w-36 text-center`}>{employee.position}</p>
                <p className='w-32 text-center bg-purple-400 rounded-md text-white'>{employee?.role}</p>
                <span className={`w-24 px-2 py-[2px] rounded-md text-center text-white ${status === "active" ? "bg-green-400" : "bg-red-500"}`}>{status === "active" ? "Active" : "InActive"}</span>
                <span className={`${classes.button_group} w-24`}>
                    <button onClick={handleModal} className={classes.button}>
                        <BiSolidEdit cursor={"pointer"} size={20} color="green" />
                    </button>
                    <button onClick={() => setIsDeleteModal(true)} className={classes.button}>
                        <BiSolidTrashAlt cursor={"pointer"} size={20} color="red" />
                    </button>
                </span>
            </div> */}

            {
                isModalOpen &&
                <AddEmployeeModal
                    handleModal={handleModal}
                    isUpdate={true}
                    employee={employee}
                    getAllEmployee={getAllEmployee}
                />
            }

            {isDeleteModal &&
                <DeleteModal
                    setState={setIsDeleteModal}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

export default Employee