import TodaysTodos from '@/components/todos/TodaysTodos';
import { useParams } from 'react-router-dom';

const TodaysTodosPage = () => {
    const params = useParams();
console.log("params", params);

    return (
        <div>
            <TodaysTodos
                viewAllBtn={false}
                date={params?.date || ""}
            />
        </div>
    )
}

export default TodaysTodosPage