// import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import SubFolders from '../folders/SubFolders'
import SubSubFolders from '../folders/SubSubFolders'
import SubSubFolderDocs from '../folders/SubSubFolderDocs'
import Folders from '../folders/Folders'
import { FaArrowLeft, FaCirclePlus } from 'react-icons/fa6'
import AddFolderModal from '../add-folderModal/AddFolderModal'
import useGetApiReq from '@/hooks/useGetApiReq'
import { readCookie } from '@/utils/readCookie'


const ProjectDocumentsModal = ({ isDocumentsModalOpen, setIsDocumentsModalOpen }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const [folderName, setFolderName] = useState("");
    const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFolderName(value);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!folderName) {
            toast.error("Folder name is required");
            return;
        }


        fetchData("/admin/add-folder", { name: folderName });

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsDocumentsModalOpen(false)
        }
    }, [res])


    const { res: folderRes, fetchData: fetchFolderData, isLoading: isFolderLoading } = useGetApiReq();
    const [allFolders, setAllFolders] = useState([]);

    const getFolders = () => {
        fetchFolderData(`/admin/get-all-folders?projectId=${params.id || params.pId || ""}`);
    }

    useEffect(() => {
        getFolders();
    }, [])

    useEffect(() => {
        if (folderRes?.status === 200 || folderRes?.status === 201) {
            console.log("folders", folderRes);
            setAllFolders(folderRes?.data?.allFolders);
        }
    }, [folderRes])

    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const userInfo = readCookie("userInfo")

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId|| params?.id}`);
    }

    useEffect(() => {
        getProjectManagerStatus()
    }, [])

    console.log("allFolders", allFolders);

    return (
        <Dialog open={isDocumentsModalOpen} onOpenChange={() => setIsDocumentsModalOpen(!isDocumentsModalOpen)}>
            <DialogContent className="sm:max-w-[925px]">
                <DialogHeader>
                    <DialogTitle>All Folders</DialogTitle>
                </DialogHeader>
                <div className='flex justify-between'>
                    <div className='mt-3 flex gap-2 items-center'>
                        {pathname.split("/").length > 3 && <FaArrowLeft className='cursor-pointer' onClick={() => navigate(-1)} />}
                        {pathname}
                    </div>
                    {(managerRes?.data?.data?.employeeId === userInfo?.userId || userInfo.userType === "admin") && <button onClick={() => setIsAddFolderModalOpen(true)} className="px-4 py-2 rounded-md flex items-center gap-3 border-2">
                        <FaCirclePlus size={18} />
                        Add Folder
                    </button>}
                </div>
                <Routes>
                    <Route path="/" element={<Folders getFolders={getFolders} allFolders={allFolders} />} />
                    <Route path="/:folderName" element={<SubFolders />} />
                    <Route path="/:folderName/:subFolderName" element={<SubSubFolders />} />
                    <Route path="/:folderName/:subFolderName/:subSubFolderName" element={<SubSubFolderDocs />} />
                </Routes>
                {isAddFolderModalOpen &&
                    <AddFolderModal
                        isAddFolderModalOpen={isAddFolderModalOpen}
                        setIsAddFolderModalOpen={setIsAddFolderModalOpen}
                        projectId={params?.id || params?.pId}
                        getFolders={getFolders}
                    />
                }
            </DialogContent>
        </Dialog>
    )
}

export default ProjectDocumentsModal