import classes from "./CreateInvoice.module.css";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import InvoiceModal from "../../components/invoiceModal/InvoiceModal";
import SuccessModal from "../../components/successModal/SuccessModal";
import { validate } from "../../utils/validate";
import { openCloseBody } from "../../store/slices/bodySlice";
import { useDispatch } from "react-redux";
import usePostApiReq from "../../hooks/usePostApiReq";
import Loader from "@/components/loader/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import logo from '../../assets/Easesmith logo.png'

const CreateInvoice = () => {
  const { res, fetchData, isLoading } = usePostApiReq();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInvoicePreview, setIsInvoicePreview] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    clientId: state?._id || "",
    projectId: state?._id || "",
    subTotal: 0,
    taxRate: "",
    Terms: "",
    total: 0,
    date: "",
    dueAmount: "",
    invoiceNumber: "",
  });
  const [services, setServices] = useState([]);
  const [serviceData, setServiceData] = useState({
    serviceName: "",
    desccription: "",
    price: "",
  });
  const [validateState, setValidateState] = useState([]);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);


  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === 'taxRate') {
      let totalTax = (invoiceData.subTotal * value) / 100
      setInvoiceData({ ...invoiceData, taxRate: value, total: invoiceData.subTotal + totalTax })
    }
    else {
      setInvoiceData({ ...invoiceData, [name]: value });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
  };

  const handleAddService = () => {
    if (!serviceData.serviceName || !serviceData.desccription || !serviceData.price) {
      return;
    }
    setServices((prev) => {
      return [...prev, serviceData];
    });
    setServiceData({ serviceName: "", desccription: "", price: "" })
  };

  useEffect(() => {
    calculateSubtotal();
  }, [services])

  useEffect(() => {
  }, [])

  useEffect(() => {
    const handleModalOpen = () => {
      if (isInvoicePreview || isSuccessModal) {
        dispatch(openCloseBody(true));
      }
      else {
        dispatch(openCloseBody(false));
      }
    }
    handleModalOpen()
  }, [isInvoicePreview, isSuccessModal])


  useEffect(() => {
    let totalTax = (invoiceData.subTotal * invoiceData.taxRate) / 100
    setInvoiceData({ ...invoiceData, total: invoiceData.subTotal + totalTax })
  }, [invoiceData.subTotal])


  const calculateSubtotal = () => {
    if (services.length === 0) {
      return
    }
    let sum = 0;
    services.forEach((service) => {
      sum += parseInt(service.price);
    })
    setInvoiceData({ ...invoiceData, subTotal: sum })
  }

  const createInvoice = async () => {
    const res = validate({
      "Sub Total": invoiceData.subTotal,
      "Tax Rate": invoiceData.taxRate,
      "Terms": invoiceData.Terms,
      "Total": invoiceData.total,
      "Date": invoiceData.date,
      "Due Amount": invoiceData.dueAmount,
      "Invoice Number": invoiceData.invoiceNumber,
      "Service": services.length === 0 ? "" : services,
    });

    const validateArr = [];
    for (const key in res) {
      if (!res[key].status) {
        validateArr.push(key);
        setValidateState(validateArr);
      }
      else {
        const temp = validateArr.filter((item) => item !== key);
        setValidateState(temp);
      }
    }
    if (
      !invoiceData.invoiceNumber
      || !invoiceData.date
      || !invoiceData.subTotal
      || !invoiceData.taxRate
      || !invoiceData.total
      || !invoiceData.Terms
      || services.length === 0
    ) {
      return;
    }
    setIsInvoiceLoading(true);
    fetchData("/admin/create-invoice", { ...invoiceData, services });
  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setIsInvoiceLoading(false);
      setInvoiceData({
        subTotal: 0,
        taxRate: "",
        Terms: "",
        total: 0,
        date: "",
        invoiceNumber: "",
        dueAmount: ""
      })
      setServices([])
      navigate(`/clients/${state._id}`, { state });
    }
  }, [res])


  return (
    <section>
      <h1 className="text-3xl font-bold py-2 mb-3">Create Invoice</h1>

      <div className="rounded-[30px] shadow p-4 border-8 border-[#E5EBF3]">
        <div className="flex justify-between gap-3 bg-[#F2F5F9] p-8 rounded-2xl">
          <div>
            <h2 className="text-2xl font-bold">Invoice</h2>
            <div className="mt-9 flex flex-col gap-1">
              <h4 className="text-[#60737D]">Billed To:</h4>
              <h2 className="text-xl font-bold">{state?.companyName}</h2>
              <p className="text-[#374151] text-sm">{state?.phone}</p>
              <p className="text-[#374151] text-sm">{`${state?.address?.addressLine1},${state?.address?.addressLine2}, ${state?.address?.city} ${state.address.state}, ${state.address.country}, ${state.address.zipCode}`}</p>
            </div>
          </div>
          <div className="flec flex-col gap-4">
            <div>
              <h4 className="text-[#60737D] mb-1 text-right">Invoice No.:</h4>
              <input
                onChange={handleOnChange}
                value={invoiceData.invoiceNumber}
                className="px-2 py-1 rounded border border-[#9CA3AF] w-36"
                type="text"
                name="invoiceNumber"
                id="invoiceNumber"
              />
              {/* <h2 className="text-xl font-bold">#000233</h2> */}
            </div>
            <div className="mt-9">
              <h4 className="text-[#60737D] mb-1 text-right">Issued on:</h4>
              <input
                onChange={handleOnChange}
                value={invoiceData.date}
                className="px-2 py-1 rounded border border-[#9CA3AF] w-36"
                type="date"
                name="date"
                id="date"
              />
            </div>
            <div className="mt-1">
              <h4 className="text-[#60737D] mb-1 text-right">Payment Due:</h4>
              <input
                onChange={handleOnChange}
                value={invoiceData.dueAmount}
                className="px-2 py-1 rounded border border-[#9CA3AF] w-36"
                type="number"
                name="dueAmount"
                id="dueAmount"
              />
            </div>
          </div>
        </div>

        <Table className="mt-4 w-full">
          <TableHeader>
            <TableRow>
              <TableHead>Service Name</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Price (₹)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              services.map((service, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <p>{service.serviceName}</p>
                  </TableCell>
                  <TableCell>
                    <p>{service.desccription}</p>
                  </TableCell>
                  <TableCell>
                    <p>₹ {service.price}</p>
                  </TableCell>
                </TableRow>
              ))
            }
            <TableRow>
              <TableCell>
                <input
                  onChange={onChange}
                  value={serviceData.serviceName}
                  className="px-2 py-2 rounded border border-[#9CA3AF] w-[300px]"
                  type="text"
                  name="serviceName"
                  id="serviceName"
                />
              </TableCell>
              <TableCell>
                <textarea
                  onChange={onChange}
                  value={serviceData.desccription}
                  className="px-2 py-1 rounded border border-[#9CA3AF] w-[500px]"
                  name="desccription"
                  id="desccription"
                ></textarea>
              </TableCell>
              <TableCell>
                <input
                  onChange={onChange}
                  value={serviceData.price}
                  className="px-2 py-2 rounded border border-[#9CA3AF] w-[200px]"
                  type="number"
                  name="price"
                  id="price"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="flex justify-end items-center mt-3">
          <button
            onClick={handleAddService}
            className="px-4 py-2 border-2 rounded-md"
          >
            Add Service
          </button>
        </div>

        <div className="flex  justify-end mt-4">
          <div className="flex flex-col items-end gap-2">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3">
                <p>Subtotal:</p>
                <p>₹ {invoiceData.subTotal}</p>
              </div>
              <div className="flex items-center gap-3">
                <p>Tax Rate (%):</p>
                <input
                  onChange={handleOnChange}
                  value={invoiceData.taxRate}
                  className="px-2 py-1 rounded border border-[#9CA3AF] w-16"
                  type="number"
                  placeholder="12"
                  name="taxRate"
                  id="taxRate"
                />
              </div>
              <div className="flex items-center gap-3">
                <p>Total:</p>
                <p>₹ {invoiceData.total}</p>
              </div>
            </div>

            <div className="bg-[#F2F5F9] rounded-md p-3 flex items-center justify-between w-80 gap-3">
              <p>Total (INR):</p>
              <p className="text-2xl font-bold">{invoiceData.total}</p>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h2 className="text-2xl font-bold">From</h2>
          <img className="mt-2 w-60" src={logo} alt='logo' />
          <div className="grid grid-cols-3 mt-4">
            <div>
              <p>Address: 1/39 vishesh khand
                Near summit Building Lucknow ,
                Uttar Pradesh - INDIA
              </p>
              <span className="text-[#334BC8] mt-1">info@easesmith.com</span>
            </div>
            <div>
              <h3 className="text-xl font-bold">Payment Instructions</h3>
              <p className="mt-1 text-[#60737D]">
                Voluptas nisi aut. Est vitae dolore molestias porro praesentium. Tempore recusandae voluptatem necessitatibus corporis inventore neque magnam ut.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold">Additional Notes</h3>
              <textarea className="px-2 py-1 mt-1 text-[#60737D] rounded border border-[#9CA3AF] w-full" onChange={handleOnChange} value={invoiceData.Terms} name="Terms" id="Terms" rows="5"></textarea>
            </div>
          </div>

          {validateState.length > 0 && <p className="text-red-400 mt-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
          <div className={classes.button_group}>
            <button onClick={() => setIsInvoicePreview(true)} className="px-4 py-2 text-white rounded-md bg-[#1A56DB]">Preview Invoice</button>
            <button onClick={createInvoice} className="px-4 py-2 border-2 rounded-md">
              {isInvoiceLoading ?
                <Loader /> : "Save Invoice"}
            </button>
          </div>
        </div>
      </div>

      
      {isInvoicePreview &&
        <InvoiceModal
          setIsInvoicePreview={setIsInvoicePreview}
          services={services}
          subtotal={invoiceData.subTotal}
          taxRate={invoiceData.taxRate}
          total={invoiceData.total}
          invoiceNumber={invoiceData.invoiceNumber}
          invoiceDate={invoiceData.date}
          state={state}
          Terms={invoiceData.Terms}
        />}
      {isSuccessModal &&
        <SuccessModal
          message={"Invoice created successfully"}
          setState={setIsSuccessModal}
        />
      }
    </section>
  );
};
export default CreateInvoice;