import { useEffect, useState } from 'react';
import classes from './CreateprojectModal.module.css';
import { axiosInstance } from '../../utils/axiosInstance';

import { RxCross2 } from 'react-icons/rx';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { validate } from '../../utils/validate';
import { useDispatch } from 'react-redux';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useParams } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import usePostApiReq from '../../hooks/usePostApiReq';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import useGetApiReq from '../../hooks/useGetApiReq';

const CreateProjectModal = ({ isUpdate, project = "", setState, getAllProject }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: projectManagerRes, fetchData: fetchProjectManagerData, isLoading: isProjectManagerLoading } = usePostApiReq();
    const { res: updateRes, fetchData: fetchUpdateData, isLoading: isUpdateLoading } = usePatchApiReq();
    const { res: clientRes, fetchData: fetchClientData, isLoading: isClientLoading } = useGetApiReq();
    const { res: serviceRes, fetchData: fetchServiceData, isLoading: isServiceLoading } = useGetApiReq();

    const params = useParams();

    console.log("project from create", project);

    const [projectInfo, setProjectInfo] = useState({
        clientId: project?.clientId?._id || params?.clientId || "",
        projectName: project?.projectName || "",
        projectCost: project?.projectCost || "",
        projectExpense: project?.projectExpense || "",
        startDate: project?.startDate && format(new Date(project.startDate), "yyyy-MM-dd") || "",
        endDate: project?.endDate && format(new Date(project.endDate), "yyyy-MM-dd") || "",
        deadline: project?.deadline && format(new Date(project.deadline), "yyyy-MM-dd") || "",
        timeDuration: project?.timeDuration || "",
        status: project?.status || "",
        devUrl: project?.devUrl || "",
        prodUrl: project?.prodUrl || "",
        img: project?.logo || "",
        type: project?.type || "internal",
        services: project?.services || [],
        previewImage: "",
        projectManager: "",
        projectManagerAccess: "",
        projectNumber: project?.projectUrl || "",
    });
    const [allClients, setAllClients] = useState([]);
    const [validateState, setValidateState] = useState([]);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const [isPreview, setIsPreview] = useState(project ? false : true);

    console.log("project", projectInfo);
    const { res: empRes, fetchData: fetchEmpData, isLoading: isEmpLoading } = useGetApiReq();

    const [allEmployees, setAllEmployees] = useState([]);

    const getAllEmployee = async () => {
        fetchEmpData("/admin/get-employee");
    }

    useEffect(() => {
        getAllEmployee()
    }, [])

    useEffect(() => {
        if (empRes?.status === 200 || empRes?.status === 201) {
            setAllEmployees(empRes?.data?.data);
        }
    }, [empRes])

    const dispatch = useDispatch();

    const handleModalOpen = () => {
        setState(() => false);
        dispatch(openCloseBody(false));
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setProjectInfo({ ...projectInfo, [name]: value });
    }

    const getImage = (event) => {
        event.preventDefault();
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setIsPreview(true);
                setProjectInfo({
                    ...projectInfo,
                    previewImage: this.result,
                    img: uploadedImage,
                });
            });
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        let res;
        if (projectInfo.type === "client") {
            res = validate({
                "Project Name": projectInfo.projectName,
                "Project Number": projectInfo.projectNumber,
                "Client": projectInfo.clientId,
                "Type": projectInfo.type,
                "Status": projectInfo.status,
                "Services": projectInfo.services,
            });
        }
        else {
            res = validate({
                "Project Name": projectInfo.projectName,
                "Project Number": projectInfo.projectNumber,
                "Type": projectInfo.type,
                "Status": projectInfo.status,
                "Services": projectInfo.services,
            });
        }

        console.log("validate", res);
        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }
        if ((projectInfo.type === "client" && !projectInfo.clientId)
            || !projectInfo.projectName
            || !projectInfo.projectNumber
            || !projectInfo.type
            || !projectInfo.status
        ) {
            return;
        }

        const formdata = new FormData();
        formdata.append("clientId", projectInfo.clientId);
        formdata.append("deadline", projectInfo.deadline);
        formdata.append("projectCost", projectInfo.projectCost);
        formdata.append("projectExpense", projectInfo.projectExpense);
        formdata.append("projectName", projectInfo.projectName);
        formdata.append("startDate", projectInfo.startDate);
        formdata.append("status", projectInfo.status);
        formdata.append("timeDuration", projectInfo.timeDuration);
        formdata.append("img", projectInfo.img);
        formdata.append("type", projectInfo.type);
        formdata.append("services", JSON.stringify(projectInfo.services));
        formdata.append("employeeId", projectInfo.projectManager);
        formdata.append("permissions", projectInfo.projectManagerAccess);
        formdata.append("projectUrl", projectInfo.projectNumber);
        formdata.append("devUrl", projectInfo.devUrl);
        formdata.append("prodUrl", projectInfo.prodUrl);

        if (isUpdate) {
            fetchUpdateData(`/admin/edit-project/${project._id}`, formdata);
        }
        else {
            fetchData("/admin/create-project", formdata);
        }

    }

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllProject();
            handleModalOpen();
        }
    }, [updateRes])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllProject();
            console.log("create project res", res);
            handleModalOpen();
        }
    }, [res])

    const getAllClient = async () => {
        fetchClientData(`/admin/all-clients-without-pg`);
    }

    useEffect(() => {
        if (clientRes?.status === 200 || clientRes?.status === 201) {
            setAllClients(clientRes?.data.AllClient);
        }
    }, [clientRes])


    const getAllServices = async () => {
        fetchServiceData(`/admin/get-all-service`);
    }

    useEffect(() => {
        if (serviceRes?.status === 200 || serviceRes?.status === 201) {
            setAllServices(serviceRes?.data.services);
        }
    }, [serviceRes])

    useEffect(() => {
        getAllClient();
        getAllServices();
    }, [])

    const handleProductOnChange = (e, name) => {
        const { value, checked } = e.target;
        if (checked) {
            setProjectInfo({ ...projectInfo, services: [...projectInfo.services, { serviceId: value, name }] })
        }
        else {
            const filtered = projectInfo.services.filter((service) => service.serviceId !== value);
            setProjectInfo({ ...projectInfo, services: filtered })
        }
    }


    const handleRemoveProduct = (id) => {
        const filtered = projectInfo.services.filter((service) => service.serviceId !== id);
        setProjectInfo({ ...projectInfo, services: filtered })
    }



    return (
        <div className={classes.wrapper}>
            <div className={classes.box}>
                <div className={classes.heading_container}>
                    <h3 className='text-2xl'>{isUpdate ? "Update" : "Create"} project</h3>
                    <RxCross2 onClick={handleModalOpen} cursor={"pointer"} size={26} />
                </div>
                <form onSubmit={handleOnSubmit}>
                    <div className={classes.input_container}>
                        <label htmlFor="img">Project Image</label>
                        <div className='flex items-center'>
                            {isPreview && projectInfo.previewImage &&
                                <img
                                    className="w-28 h-28 rounded-full m-auto"
                                    src={projectInfo.previewImage}
                                    alt="preview image"
                                />
                            }
                            {!isPreview && projectInfo.img &&
                                <img
                                    className="w-28 h-28 rounded-full m-auto"
                                    src={`${import.meta.env.VITE_IMAGE_URL}/${projectInfo.img}`}
                                    alt="preview image"
                                />
                            }
                            <input onChange={getImage} type="file" id="img" name="img" />
                        </div>
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="projectName">Project Name</label>
                        <input onChange={handleOnChange} value={projectInfo.projectName} className={classes.input} type="text" placeholder="Enter Project Name" name="projectName" id="projectName" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="projectNumber">Project Number</label>
                        <input onChange={handleOnChange} value={projectInfo.projectNumber} className={classes.input} type="text" placeholder="Enter Project Number" name="projectNumber" id="projectNumber" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="projectCost">Project Cost</label>
                        <input onChange={handleOnChange} value={projectInfo.projectCost} className={classes.input} type="number" placeholder="Enter Project Cost" name="projectCost" id="projectCost" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="projectExpense">Project Expense</label>
                        <input onChange={handleOnChange} value={projectInfo.projectExpense} className={classes.input} type="number" placeholder="Enter Project Expense" name="projectExpense" id="projectExpense" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="devUrl">Development Url</label>
                        <input onChange={handleOnChange} value={projectInfo.devUrl} className={classes.input} type="url" placeholder="Enter Development Url" name="devUrl" id="devUrl" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="prodUrl">Production Url</label>
                        <input onChange={handleOnChange} value={projectInfo.prodUrl} className={classes.input} type="url" placeholder="Enter Production Url" name="prodUrl" id="prodUrl" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="Products">Services</label>
                        <div onClick={() => setIsMultiSelectOpen(!isMultiSelectOpen)} className={`${classes.input} ${classes.d_flex}`}>
                            select Services
                            <IoIosArrowDown />
                        </div>
                        {isMultiSelectOpen &&
                            <div className={classes.multi_select}>
                                {allServices?.map((service) => (
                                    <div key={service._id} className={classes.d_flex}>
                                        <label htmlFor={service.name}>{service.name}</label>
                                        <input checked={projectInfo.services.some((item) => item.serviceId === service._id)} onChange={(e) => handleProductOnChange(e, service?.name)} type="checkbox" value={service._id} name={service.name} id={service.name} />
                                    </div>
                                ))}
                            </div>
                        }
                        <div className={classes.service_container}>
                            {projectInfo.services.length > 0 && projectInfo.services.map((item) => (
                                <span key={item.name} className={classes.service}>
                                    {item?.name ? item.name : item.serviceId.name}
                                    <MdClose cursor={"pointer"} size={20} onClick={() => handleRemoveProduct(item.serviceId)} />
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="timeDuration">Time Duration</label>
                        <input onChange={handleOnChange} value={projectInfo.timeDuration} className={classes.input} type="text" placeholder="Enter Project Time Duration" name="timeDuration" id="timeDuration" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="startDate">StartDate</label>
                        <input onChange={handleOnChange} value={projectInfo.startDate} className={classes.input} type="date" placeholder="Enter StartDate" name="startDate" id="startDate" />
                    </div>
                    {/* <div className={classes.input_container}>
                        <label htmlFor="endDate">EndDate</label>
                        <input onChange={handleOnChange} value={projectInfo.endDate} className={classes.input} type="date" placeholder="Enter endDate" name="endDate" id="endDate" />
                    </div> */}
                    <div className={classes.input_container}>
                        <label htmlFor="deadline">Deadline</label>
                        <input onChange={handleOnChange} value={projectInfo.deadline} className={classes.input} type="date" placeholder="Enter deadline" name="deadline" id="deadline" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="status">Status</label>
                        <select onChange={handleOnChange} value={projectInfo.status} className={classes.input} name="status" id="status">
                            <option value="">Select</option>
                            <option value="ongoing">Ongoing</option>
                            <option value="completed">Completed</option>
                            <option value="upcoming">Upcoming</option>
                        </select>
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="type">Type</label>
                        <select onChange={handleOnChange} value={projectInfo.type} className={classes.input} name="type" id="type">
                            {/* <option value="">Select</option> */}
                            <option value="internal">Internal</option>
                            <option value="client">Client</option>
                        </select>
                    </div>
                    {!params.clientId && projectInfo.type !== "internal" &&
                        <div className={classes.input_container}>
                            <label htmlFor="clientId">Client</label>
                            <select onChange={handleOnChange} value={projectInfo.clientId} className={classes.input} name="clientId" id="clientId">
                                <option value="">Select</option>
                                {allClients?.map((client) => (
                                    <option key={client._id} value={client._id}>{client.founderName}</option>
                                ))}
                            </select>
                        </div>}

                    <div className={classes.input_container}>
                        <label htmlFor="projectManager">Project Manager</label>
                        <select onChange={handleOnChange} value={projectInfo.projectManager} className={classes.input} name="projectManager" id="projectManager">
                            <option value="">Select</option>
                            {allEmployees?.map((employee) => (
                                <option key={employee._id} value={employee._id}>{employee.name}</option>
                            ))}
                        </select>
                    </div>

                    {projectInfo.projectManager &&
                        <div className={classes.input_container}>
                            <label htmlFor="projectManagerAccess">Project Manager Access</label>
                            <select onChange={handleOnChange} value={projectInfo.projectManagerAccess} className={classes.input} name="projectManagerAccess" id="projectManagerAccess">
                                <option value="">Select</option>
                                <option value="read">Read</option>
                                <option value="write">Write</option>
                                <option value="none">None</option>
                            </select>
                        </div>
                    }

                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>{isUpdate ? "Update" : "Create"}</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default CreateProjectModal