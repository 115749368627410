import { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { FaCirclePlus } from 'react-icons/fa6';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import LimitComp from '../../components/limit-comp/LimitComp';
import Loader from '@/components/loader/Loader';
import AddTicketModal from '@/components/add-ticketModal/AddTicketModal';
import Search from '@/components/search/Search';
import Ticket from '@/components/ticket/Ticket';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import useGetApiReq from '@/hooks/useGetApiReq';


const ProjectTickets = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [isLoading, setIsLoading] = useState(true);
    const [allTickets, setAllTickets] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(2);
    const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);

    const [isCalender, setIsCalender] = useState(false)
    const [isCalender1, setIsCalender1] = useState(false)
    const [status, setStatus] = useState("all");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const { res, fetchData, isLoading } = useGetApiReq()

    const handleDateSelect = (value) => {
        setStartDate(value)
        setIsCalender(false)
    }

    const handleDateSelect1 = (value) => {
        setEndDate(value)
        setIsCalender1(false)
    }

    const getAllTickets = async () => {
        fetchData(`/admin/get-project-tickets/${params?.id ? params?.id : params?.pId}?page=${page}&limit=${limit}&status=${status === "all" ? "" : status}&startDate=${startDate}&endDate=${endDate}`);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("tickets", res.data)
            setPageCount(res?.data?.totalPage);
            setAllTickets(res?.data?.data);
        }
    }, [res])

    useEffect(() => {
        getAllTickets();
    }, [page, limit,startDate,endDate,status])


    return (
        <div className='mb-5'>
            <div className="flex items-center gap-2">
                <FaArrowLeft className='cursor-pointer' size={20} onClick={() => navigate(-1)} />
                <h2 className="text-3xl text-[#374151] font-bold">Project Tickets</h2>
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />
            </div>

            <div className="flex items-center gap-2 mt-8">
                <button onClick={() => setIsAddTicketModalOpen(true)} className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl flex items-center gap-3">
                    <FaCirclePlus size={18} />
                    Add Ticket
                </button>
                <Search
                    placeholder="Search Ticket"
                />
                <Select onValueChange={setStatus} value={status}>
                    <SelectTrigger className="w-[140px]">
                        <SelectValue placeholder="Select Status" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">All</SelectItem>
                        <SelectItem value="created">Created</SelectItem>
                        <SelectItem value="in-progress">In Progress</SelectItem>
                        <SelectItem value="decline">Decline</SelectItem>
                        <SelectItem value="completed">Completed</SelectItem>
                    </SelectContent>
                </Select>

                {/* <Select>
                    <SelectTrigger className="w-[140px]">
                        <SelectValue placeholder="Sort By" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="assending">Assending</SelectItem>
                        <SelectItem value="desending">Descending</SelectItem>
                    </SelectContent>
                </Select> */}

                <div className='flex gap-2 items-center'>
                    <Popover open={isCalender} onOpenChange={setIsCalender}>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[220px] text-[#717171] text-base font-normal font-inter h-10 flex justify-between",
                                    !startDate && "text-muted-foreground"
                                )}
                            >
                                {startDate ? format(startDate, "PPP") : <span>Pick a start date</span>}
                                <CalendarIcon />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                selected={startDate}
                                onSelect={handleDateSelect}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>

                    <Popover open={isCalender1} onOpenChange={setIsCalender1}>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[220px] text-[#717171] text-base font-normal font-inter h-10 flex justify-between",
                                    !endDate && "text-muted-foreground"
                                )}
                            >
                                {endDate ? format(endDate, "PPP") : <span>Pick a end date</span>}
                                <CalendarIcon />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                selected={endDate}
                                onSelect={handleDateSelect1}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                </div>

            </div>

            <Table className="mt-1">
                <TableHeader>
                    <TableRow>
                        <TableHead className="whitespace-nowrap">Ticket Id</TableHead>
                        <TableHead className="whitespace-nowrap">Subject</TableHead>
                        <TableHead className="whitespace-nowrap">Assigned</TableHead>
                        <TableHead className="whitespace-nowrap">Raised By</TableHead>
                        <TableHead className="whitespace-nowrap">Raised For</TableHead>
                        <TableHead className="whitespace-nowrap">Created Date</TableHead>
                        <TableHead className="whitespace-nowrap">Status</TableHead>
                        <TableHead className="whitespace-nowrap">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allTickets?.map((ticket) => (
                        <Ticket
                            key={ticket?._id}
                            ticket={ticket}
                            getAllTickets={getAllTickets}
                        />
                    ))}
                </TableBody>
            </Table>
            {allTickets?.length === 0 && !isLoading &&
                <p>No tickets found</p>
            }

            {allTickets.length === 0 && isLoading &&
                <Loader />
            }

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            }

            {isAddTicketModalOpen &&
                <AddTicketModal
                    setState={setIsAddTicketModalOpen}
                    getAllTickets={getAllTickets}
                    projectId={params?.id ? params?.id : params?.pId}
                />
            }
        </div>
    )
}

export default ProjectTickets