import { Link, useLocation } from 'react-router-dom';
import HomeIcn from '../../assets/icons/dashboard-img.png'
import ProIcn from '../../assets/icons/projects-img.png'
import AttIcn from '../../assets/icons/attendance-img.png'
import TicIcn from '../../assets/icons/ticket-img.png'
import AllTicIcn from '../../assets/icons/all-ticket-img.png'
import CalIcn from '../../assets/icons/calendar-img.png'
import Doc from "../../assets/icons/document-img.png"
import ResIcn from '../../assets/icons/resume-img.png'
import EmpIcn from '../../assets/icons/employee-img.png'
import ClientsIcn from '../../assets/icons/client-img.png'
import ExpIcn from "../../assets/icons/expenses-img.png"
import { IoMdMail } from "react-icons/io";
import { AiOutlineCheckSquare } from "react-icons/ai";

import classes from './Sidebar.module.css';
import { readCookie } from '../../utils/readCookie';


const Sidebar = () => {
  const { pathname } = useLocation();

  let cookies = readCookie("userInfo");
  const type = cookies?.userType;
  const permissions = cookies?.perm;
  // console.log("perm", permissions);

  return (
    <>
      <aside className={`${classes.sidebar} z-[6] h-[100vh] px-2 fixed top-0 pt-20 group p-5 bg-[#348AC8] w-[60px] overflow-hidden flex flex-col gap-2 transition-all duration-500 overflow-x-hidden overflow-y-auto hover:w-[190px]`}>
        {/* <div className={classes.aside_top}> */}
        {type === "employee" &&
          <>
            <Link className={`${classes.link} ${pathname.includes("/home") && classes.active}`} to={"/home"}>
              <img className='' src={HomeIcn} alt='dashboard' />
              <span className=''>Home</span>
            </Link>

            <Link className={`${classes.link} ${pathname.includes("/employee-projects") && classes.active}`} to={"/employee-projects"}>
              <img src={ProIcn} alt='project' />
              <span className=''>Projects</span>
            </Link>

            <Link className={`${classes.link} ${pathname.includes("/employee-attendance") && classes.active}`} to={"/employee-attendance"}>
              <img src={AttIcn} alt='Attendance' />
              <span className=''>Attendance</span>
            </Link>
            <Link className={`${classes.link} ${pathname.includes("/todos") && classes.active}`} to={"/todos"}>
              {/* <img src={Doc} alt='emails' /> */}
              <AiOutlineCheckSquare size={28} />
              <span className=''>Todos</span>
            </Link>
          </>}

        {permissions?.report !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/report") && classes.active}`} to={"/report"}>
            <img src={HomeIcn} alt='report' />
            <span className=''>Report</span>
          </Link>}

        {permissions?.allProjects !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/allProjects") && classes.active}`} to={"/allProjects"}>
            <img src={ProIcn} alt='project' />
            <span className=''>All Projects</span>
          </Link>}

        {permissions?.employees !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/employees") && classes.active}`} to={"/employees"}>
            <img src={EmpIcn} alt='employee' />
            <span className=''>Employees</span>
          </Link>}

        {permissions?.clients !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/clients") && classes.active}`} to={"/clients"}>
            <img src={ClientsIcn} alt='clients' />
            <span className=''>Clients</span>
          </Link>}

        {permissions?.allTickets !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/allTickets") && classes.active}`} to={"/allTickets"}>
            <img src={AllTicIcn} alt='allTickets' />
            <span className=''>All Tickets</span>
          </Link>}
        <Link className={`${classes.link} ${pathname.includes("/myTickets") && classes.active}`} to={"/myTickets"}>
          <img src={TicIcn} alt='myTickets' />
          <span className=''>My Tickets</span>
        </Link>
        {permissions?.calender !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/calender") && classes.active}`} to={"/calender"}>
            <img src={CalIcn} alt='calender' />
            <span className=''>Calender</span>
          </Link>}

        {permissions?.allAttendance !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/allAttendance") && classes.active}`} to={"/allAttendance"}>
            <img src={AttIcn} alt='Attendance' />
            <span className=''>All Attendance</span>
          </Link>}

        {permissions?.expenses !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/expenses") && classes.active}`} to={"/expenses"}>
            <img src={ExpIcn} alt='expense' />
            <span className=''>Expenses</span>
          </Link>}

        {permissions?.resumes !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/resumes") && classes.active}`} to={"/resumes"}>
            <img src={ResIcn} alt='resume' />
            <span className=''>Resumes</span>
          </Link>}

        {permissions?.documents !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/documents") && classes.active}`} to={"/documents"}>
            <img src={Doc} alt='documents' />
            <span className=''>Documents</span>
          </Link>}

        {permissions?.emails !== "none" &&
          <Link className={`${classes.link} ${pathname.includes("/emails") && classes.active}`} to={"/emails"}>
            {/* <img src={Doc} alt='emails' /> */}
            <IoMdMail size={28} />
            <span className=''>Emails</span>
          </Link>}
        {/* </div> */}
        <div className="mt-2">
          {/* <div className={classes.link}>
          <IoMdSettings className="text-2xl" />
          <span>Setting</span>
        </div> */}

        </div>

      </aside>
    </>
  )
}

export default Sidebar