import { format, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { TiTickOutline } from "react-icons/ti";
import { useDispatch } from "react-redux";
import AttendanceInfoModal from "../../components/attendance-infoModal/AttendanceInfoModal";
import { openCloseBody } from "../../store/slices/bodySlice";

import Loader from "@/components/loader/Loader";
import { getUserLocation } from "@/utils/getLocation";
import toast from "react-hot-toast";
import { FaXmark } from "react-icons/fa6";
import Break from "../../components/break/Break";
import "../../fc-common.css";
import usePatchApiReq from "../../hooks/usePatchApiReq";
import usePostApiReq from "../../hooks/usePostApiReq";
import { readCookie } from "../../utils/readCookie";
import AllBreaks from "./AllBreaks";

const EmployeeAttendance = () => {
  const { res, fetchData, isLoading } = usePostApiReq();
  const { res: breakRes, fetchData: breakFetchData, isLoading: breakIsLoading } = usePatchApiReq();
  const { res: availableRes, fetchData: availableFetchData, isLoading: availableIsLoading } = usePatchApiReq();

  const cookies = readCookie("userInfo");
  const [attCookies, setAttCookies] = useState(readCookie("attInfo"));
  const [statusData, setStatusData] = useState("");
  // console.log("attCookies", attCookies);
  const userId = cookies?.userId;
  const id = attCookies?.atdId;

  const [allBreaks, setAllBreaks] = useState([]);
  const [noOfAbsents, setNoOfAbsents] = useState(0);
  const [noOfPresents, setNoOfPresents] = useState(0);
  const [isBreakLoading, setIsBreakLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBreak = async () => {
    breakFetchData(`/admin/update-emp-atd/${statusData?.details?.attendanceId}`, { status: "offline" });
  };

  useEffect(() => {
    if (breakRes?.status === 200 || breakRes?.status === 201) {
      getBreaks();
      getStatus();
      handleMonthChange(new Date());
    }
  }, [breakRes])

  const handlePunchIn = async () => {
    let location = await getUserLocation();
    // console.log("location: ", location);
    if (!location) return;

    try {
      const { data } = await axiosInstance.post(`/admin/emp-punch-in/${userId}`, { coordinates: [location.longitude, location.latitude] });
      const punchInDate = parseISO(data?.data?.punchIn);
      toast.success(
        "Punched in successfully at " + format(punchInDate, "HH:mm:ss")
      );
      // getAttendance();
      handleMonthChange(new Date());
      getStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePunchOut = async () => {
    let location = await getUserLocation();
    // console.log("location: ", location);
    if (!location) return;

    try {
      const { data } = await axiosInstance.post(`/admin/emp-punch-out/${userId}`, { coordinates: [location.longitude, location.latitude] });
      const punchOutDate = parseISO(data?.data?.punchOut);
      toast.success(
        "Punched out successfully at " + format(punchOutDate, "HH:mm:ss")
      );
      getBreaks()
      getStatus()
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  // const getAttendance = async () => {
  //   try {
  //     const { data } = await axiosInstance.get(`/admin/get-emp-atd/${userId}`);
  //     setAllAttendance(data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getBreaks = async () => {
    const id = cookies?.userId;
    try {
      const { data } = await axiosInstance.get(
        `/admin/get-emp-atd-details/${id}`
      );
      console.log("emp att", data);

      setAllBreaks(data?.data[0]?.breaks || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsBreakLoading(false);
    }
  };

  const getStatus = async () => {
    const id = cookies?.userId;
    try {
      const { data } = await axiosInstance.get(
        `/admin/get-emp-atd-status/${id}`
      );
      setStatusData(data?.data);
      console.log("status", data);

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getStatus();
    getBreaks();
  }, [])


  const [allEvents, setAllEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateClick = (arg) => {
    console.log("arg", arg);
    // format(new Date(event.date),"dd-MM-yyyy")
    const data = allEvents.find((event) => {
      const date = format(new Date(event.date), "dd-MM-yyyy");
      const eventDate = format(new Date(arg.dateStr), "dd-MM-yyyy");

      return date === eventDate
    })
    console.log("event data", data);
    if (data) {
      setEvent(data);
      setModalIsOpen(true);
    }
  };

  useEffect(() => {
    const handleModalOpen = () => {
      if (modalIsOpen) {
        dispatch(openCloseBody(true));
      } else {
        dispatch(openCloseBody(false));
      }
    };
    handleModalOpen();
  }, [modalIsOpen]);

  // const handleDatesSet = () => {
  //   const currentDate = new Date();
  //   setSelectedDate(() => currentDate);
  // };

  const handleMonthChange = async (newDate) => {
    const month = format(new Date(newDate), "MM");
    const year = format(new Date(newDate), "yyyy");

    fetchData(`/admin/emp-atd-monthly?month=${month}&year=${year}`);
  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("attendance emp site", res?.data);
      setNoOfAbsents(res?.data.no_of_absents);
      setNoOfPresents(res?.data.no_of_presents);

      const Data = res?.data?.attendance?.att?.map((singleAttendance) => {
        console.log("singleAtt", singleAttendance);
        if (singleAttendance.punchIn && singleAttendance.punchOut) {
          return {
            title: "Present",
            date: singleAttendance?.punchIn,
            flag: singleAttendance.flag,
            punchIn: singleAttendance.punchIn,
            punchOut: singleAttendance.punchOut,
            breaks: singleAttendance.breaks,
          };
        } else {
          return {
            title: "Absent",
            date: singleAttendance?.punchIn,
            flag: singleAttendance.flag,
            punchIn: singleAttendance.punchIn,
            punchOut: singleAttendance.punchOut,
            breaks: singleAttendance.breaks,
          };
        }
      });
      setAllEvents(Data);
    }
  }, [res]);


  const calendarRef = useRef(null);

  const handlePrev = () => {
    const calendar = calendarRef.current.getApi();

    const currentDate = selectedDate;

    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(currentDate.getMonth() - 1);

    calendar.gotoDate(format(new Date(prevMonth), "yyyy-MM-dd"));

    setSelectedDate(() => prevMonth);
  };

  const handleNext = () => {
    const calendar = calendarRef.current.getApi();

    const currentDate = selectedDate;

    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(currentDate.getMonth() + 1);

    calendar.gotoDate(format(new Date(nextMonth), "yyyy-MM-dd"));

    setSelectedDate(() => nextMonth);
  };

  useEffect(() => {
    handleMonthChange(selectedDate);
  }, [selectedDate])

  const customButtons = {
    Prev: {
      text: "Prev",
      click: handlePrev,
    },
    Next: {
      text: "Next",
      click: handleNext,
    },
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold py-2">
          Employee Attendance
        </h2>
        {(
          <div className="flex gap-2 items-center">
            {statusData?.status === "AVAILABLE" && (
              <button
                onClick={handleBreak}
                className="border-2 disabled:bg-gray-700 px-4 py-1 rounded"
              >
                Add Break
              </button>
            )}
            {statusData?.status === "AVAILABLE" && (
              <button
                onClick={handlePunchOut}
                className="border-2 px-4 py-1 rounded"
              >
                Punch Out
              </button>
            )}

            {statusData?.status === "NOT_PUNCHED_IN" && (
              <button
                onClick={handlePunchIn}
                className="border-2 px-4 py-1 rounded"
              >
                Punch In
              </button>
            )}
          </div>
        )}
      </div>

      <AllBreaks
        getStatus={getStatus}
        allBreaks={allBreaks}
        handleMonthChange={handleMonthChange}
        statusData={statusData}
        getBreaks={getBreaks}
        isBreakLoading={isBreakLoading}
      />

      {/* <h3 className="text-xl mt-4">All Attendance</h3>
            <div className="flex flex-wrap gap-3 mt-2">
                {allAttendance.map((attendance) => (
                    <div key={attendance?._id} className="border-2 p-3 border-gray-600 rounded max-w-[300px] w-full">
                        <p>Date : {format(new Date(attendance.punchIn), "dd-MM-yyyy")}</p>
                        <p>punch in : {format(new Date(attendance.punchIn), "hh:mm")}</p>
                        <p>punch out : {attendance.punchOut && format(new Date(attendance.punchOut), "hh:mm")}</p>
                    </div>
                ))}
            </div> */}

      <div className="mt-5">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={allEvents}
          height={"84vh"}
          eventContent={(eventInfo) => {
            return (
              <div
                className={`w-10 h-10 rounded-full text-white ml-2 mb-2 flex justify-center items-center ${eventInfo.event.title === "Present" && "bg-[#0E9F6E]"} ${eventInfo.event.title === "Absent" && "bg-red-500"}`}
              >
                {eventInfo.event.title === "Present" && <TiTickOutline size={22} />}
                {eventInfo.event.title === "Absent" && <FaXmark size={22} />}
              </div>
            );
          }}
          dateClick={handleDateClick}
          // datesSet={handleDatesSet}
          customButtons={customButtons}
          headerToolbar={{
            left: "today Prev Next",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
        />

        <div className="flex justify-between max-w-lg mx-auto mt-5 gap-4">
          <p className="text-lg font-semibold">
            Total Present Days : {noOfPresents}
          </p>
          <p className="text-lg font-semibold">
            Total Absent Days : {noOfAbsents}
          </p>
        </div>

        {modalIsOpen && (
          <AttendanceInfoModal setState={setModalIsOpen} event={event} />
        )}
      </div>

    </div>
  );
};

export default EmployeeAttendance;
