import { useNavigate } from 'react-router-dom';
import classes from './ProjectCard.module.css';
import { format } from 'date-fns';
import avatar from "../../assets/planning.png"
import { TableCell, TableRow } from '../ui/table';

const ProjectCard = ({ project, index }) => {
    const navigate = useNavigate();
    console.log("project", project);

    return (
        <>
            <TableRow>
                <TableCell>
                    <div onClick={() => navigate(`/allProjects/${project?._id}`, { state: project })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                        <img src={project?.logo ? `${import.meta.env.VITE_IMAGE_URL}/${project.logo}` : project?.projectId?.logo ? `${import.meta.env.VITE_IMAGE_URL}/${project?.projectId?.logo}` : avatar} className='w-full h-full' alt="avatar" />
                    </div>
                </TableCell>
                <TableCell>
                    <h3 onClick={() => navigate(`/allProjects/${project?._id}`, { state: project })} className='text-lg cursor-pointer'>{project?.projectId ? project?.projectId?.projectName : project?.projectName}</h3>
                    <p className='text-[#9CA3AF] text-sm'>{project?.clientId?.founderName ? project?.clientId?.founderName : "Internal"}</p>
                </TableCell>
                <TableCell>{project?.projectUrl}</TableCell>
                <TableCell>John Doe</TableCell>
                <TableCell>
                    <p className={`font-semibold ${project?.status === "ongoing" ? "bg-[#FFD700] text-white" : project?.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-1 py-2 w-32 text-center rounded-tl-xl rounded-br-xl`}>{project?.status}</p>
                </TableCell>
                <TableCell>₹{project?.projectId ? project?.projectId?.projectCost || 0 : project?.projectCost || 0}</TableCell>
                <TableCell>{format(new Date(project?.projectId ? project?.projectId?.startDate : project?.startDate), "dd-MM-yyyy")}</TableCell>
                <TableCell>{project?.endDate ? format(new Date(project?.projectId ? project?.projectId?.endDate : project?.endDate), "dd-MM-yyyy") : "_"}</TableCell>
            </TableRow>
        </>
    )
}

export default ProjectCard