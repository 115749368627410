import { useEffect, useState } from 'react';
import classes from './AddTicketModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { validate } from '../../utils/validate';
import usePostApiReq from '../../hooks/usePostApiReq';
import { readCookie } from '../../utils/readCookie';
import useGetApiReq from '@/hooks/useGetApiReq';
import { useParams } from 'react-router-dom';

const AddTicketModal = ({ setState, projectId = "", getAllTickets, ticketType }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: memberRes, fetchData: fetchMemberData, isLoading: loading } = useGetApiReq();

    console.log(projectId);
    const [description, setDescription] = useState("");
    const [ticketInfo, setTicketInfo] = useState({
        assignee: "",
        forId: "",
        subject: "",
        priority: "",
        img: "",
        previewImage: "",
        projectId: projectId
    });
    const [validateState, setValidateState] = useState([]);
    const [allMembers, setAllMembers] = useState([]);

    console.log("ticketInfo", ticketInfo);
    const cookies = readCookie("userInfo");
    const userId = cookies?.userId;
    const params = useParams();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setTicketInfo({ ...ticketInfo, [name]: value });
    }

    useEffect(() => {
        setTicketInfo({ ...ticketInfo, forId: "" })
    }, [ticketInfo.assignee])


    const getAllMembers = async () => {
        fetchMemberData(`/admin/get-project-emp/${params.id || params.pId}`);
    }

    useEffect(() => {
        (params.id || params.pId) && getAllMembers();
    }, [])

    useEffect(() => {
        if (memberRes?.status === 200 || memberRes?.status === 201) {
            console.log("memberRes", memberRes);

            setAllMembers(memberRes?.data.data);
        }
    }, [memberRes])

    const getImage = (event) => {
        event.preventDefault();
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setTicketInfo({
                    ...ticketInfo,
                    previewImage: this.result,
                    img: uploadedImage,
                });
            });
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Assignee": ticketInfo.assignee,
            "Subject": ticketInfo.subject,
            "Priority": ticketInfo.priority,
            "Description": description,
            "Ticket Image": ticketInfo.img,
            ...(ticketInfo.assignee === "employee" && { "Raised For": ticketInfo.forId }),
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!ticketInfo.assignee
            || !ticketInfo.priority
            || !ticketInfo.subject
            || !ticketInfo.img
            || !description
            || ticketInfo.assignee === "employee" && !ticketInfo.forId
        ) {
            toast.error("dkfjdkf d")
            return;
        }
        const formdata = new FormData();
        formdata.append("assignee", ticketInfo.assignee);
        formdata.append("img", ticketInfo.img);
        formdata.append("priority", ticketInfo.priority);
        formdata.append("subject", ticketInfo.subject);
        formdata.append("description", description);
        formdata.append("createdBy", userId);
        formdata.append("forId", ticketInfo.forId);
        formdata.append("ticketType", ticketType);
        if (projectId) {
            formdata.append("projectId", ticketInfo.projectId);
        }

        fetchData(`/admin/create-ticket/${userId}`, formdata);

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllTickets();
            setState(false);
        }
    }, [res])

    return (
        <div className={classes.add_document_wrapper}>
            <div className={classes.add_document_box}>
                <div className={classes.heading_container}>
                    <h3>Add Ticket</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="img">Ticket Image</label>
                        <div className="flex gap-3">
                            {ticketInfo.previewImage &&
                                <img
                                    className="w-24 rounded m-auto"
                                    src={ticketInfo.previewImage}
                                    alt="preview image"
                                />
                            }
                            <input className={classes.border_none} onChange={getImage} type="file" id="img" name="img" />
                        </div>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="assignee">Assign</label>
                        <select value={ticketInfo.assignee} onChange={handleOnChange} id="assignee" name="assignee">
                            <option value="select">Select</option>
                            <option value="project-lead">Project Lead</option>
                            <option value="management">Management</option>
                            <option value="hr">Hr</option>
                            <option value="employee">Employee</option>
                        </select>
                    </div>
                    {ticketInfo.assignee === "employee" &&
                        <div className={classes.container}>
                            <label htmlFor="forId">Raised For</label>
                            <select value={ticketInfo.forId} onChange={handleOnChange} id="forId" name="forId">
                                <option value="select">Select raised for</option>
                                {allMembers.map((member) => (
                                    <option key={member?._id} value={member?.employeeId}>{member?.data[0]?.name}</option>
                                ))}
                            </select>
                        </div>}
                    <div className={classes.container}>
                        <label htmlFor="documentName">Subject</label>
                        <input
                            value={ticketInfo.subject}
                            onChange={handleOnChange}
                            type="text"
                            id="subject"
                            name="subject"
                            placeholder="Enter subject"
                        />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="description">Description</label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="priority">Priority</label>
                        <select value={ticketInfo.priority} onChange={handleOnChange} id="priority" name="priority">
                            <option value="">Select</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>
                        </select>
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>Create</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddTicketModal