import { useEffect, useState } from "react"

import { axiosInstance } from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import LimitComp from "../../components/limit-comp/LimitComp";
import Loader from "@/components/loader/Loader";
import Search from "@/components/search/Search";
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Ticket from "@/components/ticket/Ticket";

const Tickets = () => {
    const [allTickets, setAllTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const navigate = useNavigate();
    const dispatch = useDispatch();



    const handleTicketChange = async () => {
        setAllTickets([]);
        try {
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/get-all-ticket?page=${page}`);
            setIsLoading(false);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
            //console.log("all", data);
            setAllTickets(data?.data);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        handleTicketChange();
    }, [page, limit])


    return (
        <section>
            <h1 className="text-3xl font-bold py-2">All Tickets</h1>
            <div className="flex w-full justify-between items-center">
                <div className="flex w-full justify-between mt-8 items-center">
                    <div className="flex items-center gap-2">
                        <LimitComp
                            setLimit={setLimit}
                            limit={limit}
                        />

                        <Search
                            placeholder="Search Ticket"
                        />
                    </div>
                </div>
            </div>

            <Table className="mt-3">
                <TableHeader>
                    <TableRow>
                        <TableHead className="whitespace-nowrap">Ticket Id</TableHead>
                        <TableHead className="whitespace-nowrap">Subject</TableHead>
                        <TableHead className="whitespace-nowrap">Assigned</TableHead>
                        <TableHead className="whitespace-nowrap">Raised By</TableHead>
                        <TableHead className="whitespace-nowrap">Raised For</TableHead>
                        <TableHead className="whitespace-nowrap">Created Date</TableHead>
                        <TableHead className="whitespace-nowrap">Status</TableHead>
                        <TableHead className="whitespace-nowrap">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allTickets?.map((ticket) => (
                        <Ticket
                            key={ticket?._id}
                            ticket={ticket}
                            getAllTickets={handleTicketChange}
                        />
                    ))}
                </TableBody>
            </Table>
            {allTickets.length === 0 && !isLoading &&
                <p>No tickets found</p>
            }
            {allTickets.length === 0 && isLoading &&
                <Loader />
            }


            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </section>
    )
}

export default Tickets