import React from 'react'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog'

const SingleTodoInfoModal = ({ isSingleTodoInfoModalOpen, setIsSingleTodoInfoModalOpen, todo }) => {
    return (
        <Dialog open={isSingleTodoInfoModalOpen} onOpenChange={setIsSingleTodoInfoModalOpen}>
            <DialogContent className="max-w-[500px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>
                        <div className="upper flex flex-col items-start gap-[15px] max-large:gap-1">
                            <h2 className='text-[#000A2D] text-xl font-bold font-inter max-large:text-3xl max-med:hidden'>Todo Info</h2>
                        </div>
                    </DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <div className='-mt-3'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold'>{todo?.title}</h2>
                        <div
                            className={`${todo?.priority === "low" && "bg-green-600"} ${todo?.priority === "medium" && "bg-orange-400"} ${todo?.priority === "high" && "bg-red-500"} px-3 py-1 text-white rounded-md capitalize font-medium`}
                        >{todo?.priority}</div>
                    </div>
                    <p>{todo?.description}</p>
                    <time datetime="">{todo?.time}</time>
                    <p className='mt-3'><span className='font-bold'>Created by:</span> <span className='uppercase'>{todo?.createdBy}</span></p>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default SingleTodoInfoModal