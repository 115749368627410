import AddTodo from '@/components/todos/AddTodo';
import SingleGridTodo from '@/components/todos/SingleGridTodo';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import useGetApiReq from '@/hooks/useGetApiReq';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const Todos = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const [isAddTodoModalOpen, setIsAddTodoModalOpen] = useState(false);
    const [todos, setTodos] = useState([]);
    const [status, setStatus] = useState("all");
    const [priority, setPriority] = useState("")
    const [dayFilter, setDayFilter] = useState("custom");
    const navigate = useNavigate();

    const [isCalender, setIsCalender] = useState(false)
    const [isCalender1, setIsCalender1] = useState(false)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    console.log("startDate", startDate);
    console.log("endDate", endDate);


    const handleDateSelect = (value) => {
        setStartDate(value)
        setIsCalender(false)
    }

    const handleDateSelect1 = (value) => {
        setEndDate(value)
        setIsCalender1(false)
    }

    const getTodos = async () => {
        fetchData(`/admin/get-todos-by-date`)
    }

    useEffect(() => {
        getTodos()
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("todos response", res.data)
            setTodos(res?.data?.data)
        }
    }, [res])

    return (
        <div>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <div className="flex justify-between items-center">
                <h1 className="text-3xl font-bold py-2">To do list</h1>
                {/* <div className='flex gap-2'>
                    <Button onClick={() => setIsAddTodoModalOpen(true)}>Add Todo</Button>
                </div> */}
            </div>

            <div className="flex items-center gap-3 my-2">
                {/* <Select onValueChange={setDayFilter} value={dayFilter}>
                    <SelectTrigger className="w-28 border-2 border-[#1A56DB] text-[#1A56DB]">
                        <SelectValue placeholder="" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem className="text-[#1A56DB] focus:text-[#1A56DB]" value="today">Today</SelectItem>
                        <SelectItem className="text-[#1A56DB] focus:text-[#1A56DB]" value="weekly">Weekly</SelectItem>
                        <SelectItem className="text-[#1A56DB] focus:text-[#1A56DB]" value="monthly">Monthly</SelectItem>
                        <SelectItem className="text-[#1A56DB] focus:text-[#1A56DB]" value="custom">Custom</SelectItem>
                    </SelectContent>
                </Select> */}

                {dayFilter === "custom" &&
                    <div className='flex gap-2 items-center'>
                        <Popover open={isCalender} onOpenChange={setIsCalender}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={"outline"}
                                    className={cn(
                                        "w-[220px] text-[#717171] text-base font-normal font-inter h-10 flex justify-between",
                                        !startDate && "text-muted-foreground"
                                    )}
                                >
                                    {startDate ? format(startDate, "PPP") : <span>Pick a start date</span>}
                                    <CalendarIcon />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <Calendar
                                    mode="single"
                                    selected={startDate}
                                    onSelect={handleDateSelect}
                                    initialFocus
                                />
                            </PopoverContent>
                        </Popover>

                        <Popover open={isCalender1} onOpenChange={setIsCalender1}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={"outline"}
                                    className={cn(
                                        "w-[220px] text-[#717171] text-base font-normal font-inter h-10 flex justify-between",
                                        !endDate && "text-muted-foreground"
                                    )}
                                >
                                    {endDate ? format(endDate, "PPP") : <span>Pick a end date</span>}
                                    <CalendarIcon />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <Calendar
                                    mode="single"
                                    selected={endDate}
                                    onSelect={handleDateSelect1}
                                    initialFocus
                                />
                            </PopoverContent>
                        </Popover>
                    </div>}
            </div>

            <div className='grid grid-cols-4 gap-5 mt-4'>
                {todos?.map((todo) => (
                    <SingleGridTodo
                        key={todo?._id}
                        todo={todo}
                    />
                ))}
            </div>

            {isAddTodoModalOpen &&
                <AddTodo
                    isAddTodoModalOpen={isAddTodoModalOpen}
                    setIsAddTodoModalOpen={setIsAddTodoModalOpen}
                />
            }
        </div>
    )
}

export default Todos