import useGetApiReq from '@/hooks/useGetApiReq';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import AddTodo from './AddTodo';
import { Button } from '../ui/button';
import SingleTodo from './SingleTodo';
import Loader from '../loader/Loader';
import DataNotFound from '../DataNotFound';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '../ui/table';
import Search from '../search/Search';
import { Link, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { readCookie } from '@/utils/readCookie';
import { format } from 'date-fns';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { FormControl } from '../ui/form';

const TodaysTodos = ({ to, disabled = false, empId = "", viewAllBtn = true, date }) => {
    const [isAddTodoModalOpen, setIsAddTodoModalOpen] = useState(false);
    const { res, fetchData, isLoading } = useGetApiReq();
    const [todos, setTodos] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("all");
    const [priority, setPriority] = useState("all")
    const [createdBy, setCreatedBy] = useState("all")
    const params = useParams();
    let userInfo = readCookie("userInfo")

    const getTodos = async () => {
        fetchData(`/admin/get-all-todo?limit=5&page=${page}&empId=${empId || params?.empId || userInfo?.userId}&status=${status === "all" ? "" : status}&priority=${priority === "all" ? "" : priority}&createdBy=${createdBy === "all" ? "" : createdBy}&filterDate=${date || format(new Date(), "yyyy-MM-dd")}`);
    }

    useEffect(() => {
        getTodos()
    }, [page, status, priority, createdBy])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("todos", res.data)
            setTodos(res?.data?.foundTodos)
            setPageCount(res?.data?.pagination?.totalPages || 0)
        }
    }, [res])

    return (
        <div className='mb-3 mt-5'>
            <div className='flex justify-between items-center'>
                <h3 className='text-2xl font-bold'>To do List ({date || format(new Date(), "dd-MM-yyyy")})</h3>
                {viewAllBtn && <Link to={to} className="bg-[#1A56DB] text-white rounded-md py-1 px-3">View all</Link>}
                {/* <Button onClick={() => setIsAddTodoModalOpen(true)} size="icon" className="rounded-full">
                    <FaPlus />
                </Button> */}
            </div>
            <div className='flex gap-3 items-center mt-4'>
                <Button onClick={() => setIsAddTodoModalOpen(true)} variant="outline" className="flex gap-2 text-[#1F2A37] border-[#E5E7EB] items-center">
                    Add list
                    <FaPlus />
                </Button>

                <Select onValueChange={setPriority} value={priority}>
                    <SelectTrigger className="w-48">
                        <SelectValue placeholder="Select Priority Level" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">Select Priority Level</SelectItem>
                        <SelectItem value="low">Low</SelectItem>
                        <SelectItem value="medium">Medium</SelectItem>
                        <SelectItem value="high">High</SelectItem>
                    </SelectContent>
                </Select>

                <Select onValueChange={setStatus} value={status}>
                    <SelectTrigger className="w-36">
                        <SelectValue placeholder="Select Status" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">Select Status</SelectItem>
                        <SelectItem value="completed">Completed</SelectItem>
                        <SelectItem value="created">Created</SelectItem>
                    </SelectContent>
                </Select>

                <Select onValueChange={setCreatedBy} value={createdBy}>
                    <SelectTrigger className="w-44">
                        <SelectValue placeholder="Select Created By" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">Select Created By</SelectItem>
                        <SelectItem value="employee">Employee</SelectItem>
                        <SelectItem value="admin">admin</SelectItem>
                    </SelectContent>
                </Select>

                {/* <Search
                    placeholder="Search list, created by, Status..."
                    width='w-[500px]'
                /> */}
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Sr</TableHead>
                            <TableHead>Title</TableHead>
                            <TableHead className="w-80">Description</TableHead>
                            <TableHead>Created by</TableHead>
                            <TableHead>Time</TableHead>
                            <TableHead>Priority</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {todos.map((todo, i) => (
                            <SingleTodo
                                key={todo._id}
                                index={i}
                                todo={todo}
                                disabled={disabled}
                                getTodos={getTodos}
                            />
                        ))}
                    </TableBody>
                </Table>

                {todos.length === 0 && isLoading &&
                    <Loader />
                }

                {todos.length === 0 && !isLoading &&
                    <DataNotFound name="Todo" />
                }
            </div>

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}

            {isAddTodoModalOpen &&
                <AddTodo
                    isAddTodoModalOpen={isAddTodoModalOpen}
                    setIsAddTodoModalOpen={setIsAddTodoModalOpen}
                    getTodos={getTodos}
                    empId={params?.empId || userInfo?.userId}
                />
            }
        </div>
    )
}

export default TodaysTodos