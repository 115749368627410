import React, { useEffect, useState } from 'react'
import { Checkbox } from "@/components/ui/checkbox"
import SingleTodoInfoModal from './SingleTodoInfoModal';
import { TableCell, TableRow } from '../ui/table';
import { CiMenuKebab } from "react-icons/ci";
import { BsThreeDots } from 'react-icons/bs';
import usePatchApiReq from '@/hooks/usePatchApiReq';
import AddTodo from './AddTodo';
import { useParams } from 'react-router-dom';
import { readCookie } from '@/utils/readCookie';

const SingleTodo = ({ todo, index, disabled = false, getTodos }) => {
    const [isCompleted, setIsCompleted] = useState(todo?.status === "completed" ? true : false);
    const [isSingleTodoInfoModalOpen, setIsSingleTodoInfoModalOpen] = useState(false);
    const [isTodoUpdateModalOpen, setIsTodoUpdateModalOpen] = useState(false);
    const { res, fetchData, isLoading } = usePatchApiReq();
    const params = useParams();
    let userInfo = readCookie("userInfo")

    const updateTodos = async (value) => {
        setIsCompleted(value);
        fetchData(`/admin/update-todo`, {
            todoId: todo?._id,
            status: value ? "completed" : "created"
        })
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("update todos", res.data)
        }
    }, [res])


    return (
        <>
            {/* <div className='border rounded-md p-3 grid grid-cols-[1fr_110px]'>
                <div onClick={() => setIsSingleTodoInfoModalOpen(true)} className='cursor-pointer'>
                    <h2 className='text-xl font-medium'>{todo?.title}</h2>
                    <p className='line-clamp-1'>{todo?.description}</p>
                </div>
                <div className='flex gap-2 items-center justify-between'>
                    <div
                        className={`${todo?.priority === "low" && "bg-green-600"} ${todo?.priority === "medium" && "bg-orange-400"} ${todo?.priority === "high" && "bg-red-500"} px-3 py-1 text-white font-medium`}
                    >{todo?.priority}</div>
                    <Checkbox checked={isCompleted} onCheckedChange={handleOnChange} className="w-7 h-7 border-black data-[state=checked]:bg-green-500" disabled={disabled} />
                </div>

            </div> */}
            <TableRow>
                <TableCell className="whitespace-nowrap">{index + 1}</TableCell>
                <TableCell className="whitespace-nowrap">{todo?.title}</TableCell>
                <TableCell className="line-clamp-1">{todo?.description}</TableCell>
                <TableCell className="whitespace-nowrap">{todo.createdBy}</TableCell>
                <TableCell className="whitespace-nowrap">{todo.time}</TableCell>
                <TableCell className="whitespace-nowrap">
                    <div
                        className={`rounded-md w-24 text-center ${todo?.priority === "low" && "bg-green-600"} ${todo?.priority === "medium" && "bg-orange-400"} ${todo?.priority === "high" && "bg-red-500"} px-4 py-2 capitalize text-white font-medium`}
                    >{todo?.priority}</div>
                </TableCell>
                <TableCell>
                    <Checkbox checked={isCompleted} onCheckedChange={updateTodos} className="w-7 h-7 border-black data-[state=checked]:bg-green-500" disabled={disabled} />
                </TableCell>
                <TableCell>
                    <BsThreeDots className='cursor-pointer text-2xl text-[#ABABAC]' onClick={() => setIsTodoUpdateModalOpen(true)} />
                </TableCell>
            </TableRow>

            {isSingleTodoInfoModalOpen &&
                <SingleTodoInfoModal
                    isSingleTodoInfoModalOpen={isSingleTodoInfoModalOpen}
                    setIsSingleTodoInfoModalOpen={setIsSingleTodoInfoModalOpen}
                    todo={todo}
                />
            }

            {isTodoUpdateModalOpen &&
                <AddTodo
                    isAddTodoModalOpen={isTodoUpdateModalOpen}
                    setIsAddTodoModalOpen={setIsTodoUpdateModalOpen}
                    getTodos={getTodos}
                    empId={params?.empId || userInfo?.userId}
                    todo={todo}
                />
            }
        </>
    )
}

export default SingleTodo