import { useEffect, useState } from "react";
import { TableCell, TableRow } from "../ui/table"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openCloseBody } from "@/store/slices/bodySlice";
import DeleteModal from "../deleteModal/DeleteModal";
import { FaEye } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { axiosInstance } from "@/utils/axiosInstance";

const Ticket = ({ ticket, getAllTickets, assignedEmployee = "" }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleModalOpen = () => {
            if (isDeleteModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isDeleteModalOpen])

    const handleDeleteModal = (id) => {
        setIsDeleteModalOpen(true);
        setDeleteId(id);
    }

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-admin-ticket/${deleteId}`);
            toast.success("Ticket deleted successfully");
            setIsDeleteModalOpen(false);
            getAllTickets();
            //console.log(data);
        } catch (error) {
            //console.log(error);
            toast.error(error?.response?.data?.message);
        }
    }
    return (
        <>
            <TableRow>
                <TableCell className="whitespace-nowrap">#{ticket._id}</TableCell>
                <TableCell className="whitespace-nowrap">{ticket.subject}</TableCell>
                <TableCell className="whitespace-nowrap">{ticket.assignee}</TableCell>
                <TableCell className="whitespace-nowrap">{ticket?.createdBy?.name || "-"}</TableCell>
                <TableCell className="whitespace-nowrap">{ticket?.createdFor?.name || "-"}</TableCell>
                <TableCell className="whitespace-nowrap">{format(new Date(ticket?.createdAt), "dd-MM-yyy")}</TableCell>
                <TableCell className="whitespace-nowrap">
                    <div className={`w-[120px] text-center rounded-tl-xl rounded-br-xl py-2 text-white ${ticket.status === "completed" ? "bg-green-400" : (ticket.status === "in-progress") ? "bg-yellow-400" : (ticket.status === "created") ? "bg-sky-500" : "bg-red-500"}`}>{ticket.status}</div>
                </TableCell>
                <TableCell className="whitespace-nowrap">
                    <div className="flex items-center gap-3 text-xl">
                        <FaEye onClick={() => navigate(`/myTickets/${ticket._id}`, { state: { ticket, assignedEmployee } })} cursor={"pointer"} />
                        <MdDelete onClick={() => handleDeleteModal(ticket?._id)} color="red" cursor={"pointer"} />
                    </div>
                </TableCell>
            </TableRow>

            {isDeleteModalOpen &&
                <DeleteModal
                    setState={setIsDeleteModalOpen}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

export default Ticket