import useGetApiReq from '@/hooks/useGetApiReq';
// import { saveDoc } from '@/utils/download';
import React, { useEffect, useState } from 'react'
import { FaFolder } from 'react-icons/fa6';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/components/ui/context-menu"
import AddDocumentModal from '../add-documentModal/AddDocumentModal';
import { readCookie } from '@/utils/readCookie';

const SubSubFolder = ({ subfolder, setFolderIdAndType, folderIdAndType, getSubFolders }) => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();
    const navigate = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();

    const [subFolderDocs, setSubFolderDocs] = useState([]);
    const [isSubFolderOpen, setIsSubFolderOpen] = useState(false);
    const [subFolderId, setSubFolderId] = useState("");
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);


    const handleDelete = () => {
        fetchData(`/admin/delete-sub-folder?subfolderId=${subfolder?._id}`);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("delete response", res);
            getSubFolders()
        }
    }, [res])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("sub 'sub' FolderRes", subFolderRes);
            // setAllSubFolders(subFolderRes?.data?.subfolders);
            setSubFolderDocs(subFolderRes?.data?.updateDocs);
        }
    }, [subFolderRes])

    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const userInfo = readCookie("userInfo")

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId}`);
    }

    useEffect(() => {
        if (params?.pId) {
            getProjectManagerStatus()
        }
    }, [])

    const navigateUrl = `/${pathname.includes("/documents") ? "documents" : userInfo.userType === "employee" ? `employee-projects/${params?.pId}` : `allProjects/${params?.id}`}/folder/subfolder/subSubFolder`;

    return (
        <div className="cursor-pointer flex justify-center font-semibold" key={subfolder?._id}>
            {(managerRes?.data?.data?.employeeId === userInfo?.userId || userInfo.userType === "admin") ?

                <ContextMenu>
                    <ContextMenuTrigger>
                        <div className='flex flex-col gap-1 justify-center text-center'
                            onDoubleClick={() => navigate(navigateUrl, { state: { folderId: subfolder?._id } })}
                        >
                            <FaFolder className='text-yellow-400 text-8xl' />
                            {subfolder?.name}
                        </div>
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                        <ContextMenuItem className="cursor-pointer text-destructive hover:text-red-600" onClick={handleDelete}>Delete Sub Folder</ContextMenuItem>
                        <ContextMenuItem className="cursor-pointer" onClick={() => setIsAddDocumentModalOpen(true)}>Add Document</ContextMenuItem>
                    </ContextMenuContent>
                </ContextMenu>
                :
                <div className='flex flex-col gap-1 justify-center text-center'
                    onDoubleClick={() => navigate(navigateUrl, { state: { folderId: subfolder?._id } })}
                >
                    <FaFolder className='text-yellow-400 text-8xl' />
                    {subfolder?.name}
                </div>
            }

            {isAddDocumentModalOpen &&
                <AddDocumentModal
                    setState={setIsAddDocumentModalOpen}
                    isAddDocumentModalOpen={isAddDocumentModalOpen}
                    subFolderId={subfolder._id}
                    projectId={params?.id || params?.pId}
                    getAllDocuments={() => { }}
                />
            }
        </div>
    )
}

export default SubSubFolder