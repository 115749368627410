import { useEffect, useState } from 'react'
import SubFolder from '../subfolder/SubFolder'
import useGetApiReq from '@/hooks/useGetApiReq';
import Doc from '../document/Doc';
import { useLocation } from 'react-router-dom';

const SubFolders = () => {
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();

    const [allSubFolders, setAllSubFolders] = useState([]);
    const [allDocs, setAllDocs] = useState([]);
    const [isAddSubFolderModalOpen, setIsAddSubFolderModalOpen] = useState(false);

    const { state } = useLocation()

    const getSubFolders = () => {
        fetchSubFolderData(`/admin/get-folder/${state.folderId}?flag=folder`);
    }

    useEffect(() => {
        getSubFolders();
    }, [])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("subFolderRes", subFolderRes);
            setAllSubFolders(subFolderRes?.data?.subfolders);
            setAllDocs(subFolderRes?.data?.docs);
        }
    }, [subFolderRes])

    return (
        <>
            {allSubFolders.length === 0 && allDocs.length === 0 &&
                <p>No folder found</p>
            }
            <div className='grid grid-cols-7 gap-4 mt-5'>
                {allSubFolders?.map((subfolder) => (
                    <SubFolder
                        key={subfolder?._id}
                        setFolderIdAndType={() => { }}
                        subfolder={subfolder}
                        folderIdAndType={"ddd"}
                        isDownload={""}
                        getSubFolders={getSubFolders}
                    />
                ))}
                {allDocs?.map((doc) => (
                    <Doc
                        key={doc?._id}
                        doc={doc}
                    />
                ))}
            </div>
        </>
    )
}

export default SubFolders