import toast from "react-hot-toast";

export const getUserLocation = () => {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            toast.error("Geolocation is not supported by your browser.");
            reject(new Error("Geolocation is not supported by your browser."));
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                toast.success("Location fetched successfully!");
                resolve({ latitude, longitude });
            },
            (error) => {
                if (error.code === 1) {
                    toast.error("Location access denied. Please enable it in your browser settings.");
                } else if (error.code === 2) {
                    toast.error("Location unavailable. Please try again.");
                } else if (error.code === 3) {
                    toast.error("Request timed out. Please try again.");
                } else {
                    toast.error("An unknown error occurred while fetching location.");
                }
                console.error(error.message);
                reject(error);
            }
        );
    });
};
