export const validate = (obj) => {
    const validatedObj = {}
    console.log("obj",obj);
    

    for (const key in obj) {
        if (obj[key] === "" || obj[key]?.length === 0) {
            validatedObj[key] = { status: false, message: `*${key.toUpperCase()} is required.` };
        }
        else {
            validatedObj[key] = { status: true, message: "" };
        }
    }
    return validatedObj;
}